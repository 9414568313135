<template>
    <div style="padding: 20px;position: relative;">
        <a-button type="primary" style="position: absolute;top: -41px;right: 350px;" @click="$router.push(`/dataBoard/edit?type=0&year=`+year)">编辑</a-button>
        <a-modal :visible="dialogShow" :title="this.title" :footer="null" @cancel="dialogShow = false" width="650px">
            <a-table bordered :data-source="tableList" :pagination="false" rowKey="id">
                <a-table-column width="50px" align="center">
                    <template slot-scope="text, row, index">
                        <div style="width: 20px;height: 20px;background: #F0F2F5;border-radius: 50%;margin: auto;">{{ index + 1 }}</div>
                    </template>
                </a-table-column>
                <a-table-column key="deptName" title="部门" data-index="deptName"></a-table-column>
                <a-table-column key="undertakeAmount" title="承接合同">
                    <template slot-scope="text, record, index">
                        {{ text.undertakeAmount }}万元
                    </template>
                </a-table-column>
                <a-table-column key="control" title="操作" align="center" width="60px">
                    <template slot-scope="text, record, index">
                        <a href="#" @click.prevent="$router.push(`/produce/contract?id=${text.deptId}&year=${text.year}&from=dataBoard`)">详情</a>
                    </template>
                </a-table-column>
            </a-table>
        </a-modal>
        <div style="display: flex;">
            <div class="card" style="width: 70%;margin-right: 20px;">
                <div class="title">年度目标执行情况</div>
                <scrollPicker :tabs="yearList" @onSelect="yearChange" :default="year" tabWidth="25%"/>
                <div style="display: flex;">
                    <div class="item">
                        <div class="line"></div>
                        <div class="header">
                            <span :style="'color:' + color[0]" @click="showInfo(0)">总院设计合同</span>承接情况
                        </div>
                        <div class="tip" v-if="targetInfo.percentA">
                            <div><span class="info">{{ targetInfo.percentA }}</span>%</div>
                        </div>
                        <div class="legend">
                            <!-- <div>
                                <span>已签订：</span>
                                <span >{{ pieInfo?.prodArchiveAmount }}万元</span>
                            </div> -->
                            <div>
                                <span class="bold">{{ pieInfo?.prodUndertakeAmount }}</span>万元
                            </div>
                            <div>
                                <span>年度目标</span>
                                <span>{{ pieInfo?.prodTargetAmount }}</span>万元
                            </div>
                        </div>
                        <div class="pie" id="pie1"></div>
                    </div>
                    <div class="item">
                        <div class="line"></div>
                        <div class="header">
                            <span :style="'color:' + color[1]" @click="showInfo(1)">分院设计合同</span>承接情况
                        </div>
                        <div class="tip" v-if="targetInfo.percentB">
                            <div><span class="info">{{ targetInfo.percentB }}</span>%</div>
                        </div>
                        <div class="legend">
                            <!-- <div>
                                <span>已签订：</span>
                                <span >{{ pieInfo?.branchArchiveAmount }}万元</span>
                            </div> -->
                            <div>
                                <span class="bold">{{ pieInfo?.branchUndertakeAmount }}</span>万元
                            </div>
                            <div>
                                <span>年度目标</span>
                                <span >{{ pieInfo?.branchTargetAmount }}</span>万元
                            </div>
                        </div>
                        <div class="pie" id="pie2"></div>
                    </div>
                    <div class="item">
                        <div class="line"></div>
                        <div class="header">
                            EPC合同承接情况
                        </div>
                        <div class="tip" v-if="targetInfo.percentC">
                            <div><span class="info">{{ targetInfo.percentC }}</span>%</div>
                        </div>
                        <div class="legend">
                            <!-- <div>
                                <span>已签订：</span>
                                <span >{{ pieInfo?.epcArchiveAmount }}万元</span>
                            </div> -->
                            <div>
                                <span class="bold">{{ pieInfo?.epcUndertakeAmount }}</span>万元
                            </div>
                            <div>
                                <span>年度目标</span>
                                <span >{{ pieInfo?.epcTargetAmount }}</span>万元
                            </div>
                        </div>
                        <div class="pie" id="pie3"></div>
                    </div>
                    <div class="item">
                        <div class="header">
                            智慧类合同承接情况
                        </div>
                        <div class="tip" v-if="targetInfo.percentD">
                            <div><span class="info">{{ targetInfo.percentD }}</span>%</div>
                        </div>
                        <div class="legend">
                            <!-- <div>
                                <span>已签订：</span>
                                <span >{{ pieInfo?.wisdomArchiveAmount }}万元</span>
                            </div> -->
                            <div>
                                <span class="bold">{{ pieInfo?.wisdomUndertakeAmount }}</span>万元
                            </div>
                            <div>
                                <span>年度目标</span>
                                <span >{{ pieInfo?.wisdomTargetAmount }}</span>万元
                            </div>
                        </div>
                        <div class="pie" id="pie4"></div>
                    </div>
                </div>
            </div>
            <div class="card" style="width: 30%;">
                <div class="title">
                    <span v-if="lastMonth">{{ lastMonth }}月合同承接情况</span>
                </div>
                <scrollPicker :tabs="monthList" @onSelect="monthChange" :default="lastMonth" tabWidth="40px" :scroll="false"/>
                <div id="bar1" style="width: 100%;height: 250px;"></div>
            </div>
        </div>
        <div style="display: flex;margin-top: 20px;">
            <div class="card" style="width: 50%;margin-right: 20px;">
                <div class="title">总承接合同额{{ year-3 }}-{{ year }}年同期比</div>
                <a-tabs style="width: 100%;padding: 0 10px;" defaultActiveKey="tab1" @change="tabChange($event,'bar2')">
                    <a-tab-pane tab="表格" key="tab1">
                        <a-table :dataSource="result?.yearHis" :pagination="false" :scroll="{ y:210 }">
                            <a-table-column title="年份">
                                <template slot-scope="text, record, index">
                                    {{ text.year }}年
                                </template>
                            </a-table-column>
                            <a-table-column title="总院设计(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.prodUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="分院设计(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.branchUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="EPC(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.epcUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="智慧类(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.wisdomUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane tab="图例" key="tab2">
                        <div id="bar2" style="width: 100%;height: 240px;"></div>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="card" style="width: 50%;">
                <div class="title">总承接合同额月度分布情况</div>
                <a-tabs style="width: 100%;padding: 0 10px;" defaultActiveKey="tab1" @change="tabChange($event,'bar3')">
                    <a-tab-pane tab="表格" key="tab1">
                        <a-table :dataSource="result?.monthHis" :pagination="false" :scroll="{ y:210 }">
                            <a-table-column title="月份">
                                <template slot-scope="text, record, index">
                                    {{ text.month }}月
                                </template>
                            </a-table-column>
                            <a-table-column title="总院设计(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.prodUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="分院设计(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.branchUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="EPC(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.epcUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="智慧类(万元)">
                                <template slot-scope="text, record, index">
                                    <span>{{ text.wisdomUndertakeAmount }}</span>
                                </template>
                            </a-table-column>
                        </a-table>
                    </a-tab-pane>
                    <a-tab-pane tab="图例" key="tab2">
                        <div id="bar3" style="width: 100%;height: 240px;"></div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import scrollPicker from "@/components/scroll-picker";
import {formatAmount} from "@/utils/dataBoard"
import * as echarts from "echarts/core";
import request from "@/api/request";
import {
    TooltipComponent,
    GridComponent,
    LegendComponent,
} from "echarts/components";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

function fetchList(year) {
    return request({
        url: "project-service/dsContract/total/" + year,
    });
}
function fetchByMonth(year, month) {
    return request({
        url: "project-service/dsContract/month/" + year + "/" + month,
    });
}
function fetchInfo(year,type) {
    return request({
        url: "project-service/dsContract/total/dept/" + year + "/" + type,
    });
}
echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    LineChart,
    PieChart,
    CanvasRenderer,
]);
export default {
    data() {
        return {
            result: {},
            title: '',
            tableList: [],
            dialogShow: false,
            color: ['#5E92FF', '#3CCD92', '#FFDE59', '#EA7D54'],
            targetInfo: {
                percentA: '',
                percentB: '',
                percentC: '',
                percentD: '',
            },
            year: 0,
            yearList: [],
            monthList: [],
            lastMonth: '',
            pieInfo:{},
        };
    },
    components:{
        scrollPicker
    },
    mounted() {
        this.getYearList()
        this.getMonthList()
        this.getData()
    },

    methods: {
        tabChange(e, type) {
            if(e == 'tab2') {
                this.$nextTick(()=>{
                    if(type == 'bar2'){
                        this.renderYearHisData(this.result.yearHis)
                    } else{
                        this.renderMonthHisData(this.result.monthHis)
                    }
                    
                })
                
            }
        },
        showInfo(type) {
            if(type==0){
                this.title='总院设计合同承接情况'
                fetchInfo(this.year,'ds_prod').then(res=>{
                    this.tableList = formatAmount(res).filter(v=>v.undertakeAmount)
                })
            }
            if(type==1){
                this.title='分院设计合同承接情况'
                fetchInfo(this.year,'ds_branch').then(res=>{
                    this.tableList = formatAmount(res).filter(v=>v.undertakeAmount)
                })
            }
            this.dialogShow = true;
        },
        getData() {
            fetchList(this.year).then(res => {
                res = formatAmount(res)
                this.result = res
                this.pieInfo = res.yearData;
                this.renderTarget(res.yearData)
                this.renderMonthData(res.monthData)
            })
        },
        renderTarget(data) {
            if (!data) {
                this.pie1 && this.pie1.clear()
                this.pie2 && this.pie2.clear()
                this.pie3 && this.pie3.clear()
                this.pie4 && this.pie4.clear()
                this.targetInfo = {
                    percentA: '',
                    percentB: '',
                    percentC: '',
                    percentD: '',
                }
                return
            }
            this.targetInfo.percentA = data.prodTargetAmount ? (data.prodUndertakeAmount / data.prodTargetAmount*100).toFixed(0) : 0
            this.targetInfo.percentB = data.branchTargetAmount ? (data.branchUndertakeAmount / data.branchTargetAmount*100).toFixed(0) : 0
            this.targetInfo.percentC = data.epcTargetAmount ? (data.epcUndertakeAmount / data.epcTargetAmount*100).toFixed(0) : 0
            this.targetInfo.percentD = data.wisdomTargetAmount ? (data.wisdomUndertakeAmount / data.wisdomTargetAmount*100).toFixed(0) : 0
            const list = [
                { name: "年度目标", value: data.prodTargetAmount - data.prodUndertakeAmount },
                { name: "已承接", value: data.prodUndertakeAmount },
            ]
            let option = {
                color: ["#e0e0e0", this.color[0], this.color[0]],
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        center: ['50%', '50%'],
                        name: "",
                        type: "pie",
                        radius: ['60%', '45%'],
                        label: {
                            show: false,
                            formatter: '{b}\n{c|{c}}',
                            rich: {
                                c: {
                                    fontWeight: 'bold'
                                }
                            }
                        },
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            this.pie1 = echarts.init(document.getElementById("pie1"));
            this.pie1.setOption(option);

            option.color[1] = this.color[1]
            option.color[2] = this.color[1]
            option.series[0].data = [
                { name: "年度目标", value: data.branchTargetAmount - data.branchUndertakeAmount },
                { name: "已承接", value: data.branchUndertakeAmount },
            ]
            this.pie2 = echarts.init(document.getElementById("pie2"));
            this.pie2.setOption(option);

            option.color[1] = this.color[2]
            option.color[2] = this.color[2]
            option.series[0].data = [
                { name: "年度目标", value: data.epcTargetAmount - data.epcUndertakeAmount},
                { name: "已承接", value: data.epcUndertakeAmount },
            ]
            this.pie3 = echarts.init(document.getElementById("pie3"));
            this.pie3.setOption(option);

            option.color[1] = this.color[3]
            option.color[2] = this.color[3]
            option.series[0].data = [
                { name: "年度目标", value: data.wisdomTargetAmount - data.wisdomUndertakeAmount},
                { name: "已承接", value: data.wisdomUndertakeAmount },
            ]
            this.pie4 = echarts.init(document.getElementById("pie4"));
            this.pie4.setOption(option);
        },
        renderMonthData(data) {
            if (!data) {
                this.lastMonth = ''
                this.bar1 && this.bar1.clear()
                return
            }
            this.lastMonth = data.month
            const $this = this
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {}
                },
                grid: {
                    left: '10%',
                    right: '10%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true //可容纳
                },
                xAxis: {
                    show: false
                },
                yAxis: [
                    {
                        type: 'category',
                        data: ['总院设计', '分院设计', 'EPC', '智慧类'],   //请求回来的数组y轴数据
                        inverse: true,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            color: 'black'
                        }
                    },
                    {
                        type: 'category',
                        data: [data.prodUndertakeAmount + '万', data.branchUndertakeAmount + '万', data.epcUndertakeAmount + '万', data.wisdomUndertakeAmount + '万'],
                        inverse: true,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                fontSize: 12,
                                color: 'black'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '已承接',
                        type: 'bar',
                        data: [data.prodUndertakeAmount, data.branchUndertakeAmount, data.epcUndertakeAmount, data.wisdomUndertakeAmount],
                        barWidth: 15,
                        itemStyle: {
                            color: function (params) {
                                return $this.color[params.dataIndex];
                            },
                            border: 'none',
                            borderWidth: 3,
                            barBorderRadius: 15
                        }
                    }
                ]
            }
            this.bar1 = echarts.init(document.getElementById("bar1"));
            this.bar1.setOption(option);
        },
        renderYearHisData(data) {
            if (!data) {
                this.bar2 && this.bar2.clear()
                return
            }
            let option = {
                legend: {
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: ['总院设计', '分院设计', 'EPC', '智慧类']
                },
                yAxis: {
                    type: 'value',
                    name: "(万元)",
                },
            };
            let arr = []
            let arr2 = []
            data.forEach((data, index) => {
                arr.push({
                    name: data.year,
                    data: [data.prodUndertakeAmount, data.branchUndertakeAmount, data.epcUndertakeAmount, data.wisdomUndertakeAmount],
                    type: 'bar',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    color: this.color[0],
                    itemStyle: {
                        normal: {
                            opacity: 1 / (index + 1) + 0.3,
                            barBorderRadius: [50, 50, 0, 0], // 左上，右上，右下，左下
                        }
                    }
                })
                arr2.push(data.year + '')
            });
            option.series = arr;
            option.legend.data = arr2;
            this.bar2 = echarts.init(document.getElementById("bar2"));
            this.bar2.setOption(option);
        },
        renderMonthHisData(data) {
            if (!data) {
                this.bar3 && this.bar3.clear()
                return
            }
            let option = {
                legend: {
                    icon: 'circle',
                    selected: {
                        'EPC':false,
                        '智慧类': false
                    }
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: data.map(v => v.month + '月')
                },
                yAxis: {
                    type: 'value',
                    name: "(万元)",
                },
                series: [{
                    name: '总院设计',
                    color: this.color[0],
                    data: data.map(v => v.prodUndertakeAmount),
                    type: 'line',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0], // 左上，右上，右下，左下
                        }
                    }
                }, {
                    name: '分院设计',
                    color: this.color[1],
                    data: data.map(v => v.branchUndertakeAmount),
                    type: 'line',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0], // 左上，右上，右下，左下
                        }
                    }
                },{
                    name: 'EPC',
                    color: this.color[2],
                    data: data.map(v => v.epcUndertakeAmount),
                    type: 'line',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0], // 左上，右上，右下，左下
                        }
                    }
                }, {
                    name: '智慧类',
                    color: this.color[3],
                    data: data.map(v => v.wisdomUndertakeAmount),
                    type: 'line',
                    barGap: '20%',
                    barCategoryGap: '40%',
                    itemStyle: {
                        normal: {
                            barBorderRadius: [50, 50, 0, 0], // 左上，右上，右下，左下
                        }
                    }
                }]
            };
            this.bar3 = echarts.init(document.getElementById("bar3"));
            this.bar3.setOption(option);
        },
        getYearList() {
            const now = new Date().getFullYear()
            console.log(now)
            for (let index = 2021; index <= now; index++) {
                this.yearList.push(
                    {
                        name: index,
                        value: index
                    }
                )
            }
            this.year = this.yearList[this.yearList.length - 1].value
        },
        getMonthList() {
            for (let index = 1; index <= 12; index++) {
                this.monthList.push(
                    {
                        name: index + '月',
                        value: index
                    }
                )
            }
        },
        yearChange(value) {
            this.year = value
            this.getData()
        },
        monthChange(data) {
            fetchByMonth(this.year, data).then(res=>{
                res = formatAmount(res)
                this.renderMonthData(res)
            })
        }
    }
};
</script>
<style lang="less" scoped>
.card {
    background: #FFFFFF;
    border-radius: 15px;
    margin-top: 30px;
    position: relative;
    box-shadow: 0px 3px 8px 1px #E4E9F1;
}

.item {
    width: 25%;
    margin-right: 10px;
    text-align: center;
    position: relative;
    .line{
        width: 1px;
        position: absolute;
        height: 328px;
        right: 0;
        bottom: 0;
        background: #ddd;
    }
    .header {
        margin-top: 20px;
        font-size: 16px;

        span {
            cursor: pointer;
        }
    }

    .tip {
        font-size: 12px;
        color: #595959;
        position: absolute;
        top: 64%;
        left: 0;
        right: 0;
        margin: auto;

        .info {
            font-weight: bold;
            font-size: 30px;
        }
    }

    .pie {
        width: 100%;
        height: 200px;
    }
    .legend{
        text-align: left;
        font-size: 14px;
        margin-top: 30px;
        text-align: center;
        span{
            display: inline-block;
            margin-right: 5px;
        }
        .bold{
            font-size: 30px;
            font-weight: bold;
        }
        >div{
            margin-bottom: 5px;
        }
    }
}

.title {
    font-size: 14px;
    position: absolute;
    top: -30px;
    left: 30px;
}

</style>