import { render, staticRenderFns } from "./tab5.vue?vue&type=template&id=7a5d1456&scoped=true"
import script from "./tab5.vue?vue&type=script&lang=js"
export * from "./tab5.vue?vue&type=script&lang=js"
import style0 from "./tab5.vue?vue&type=style&index=0&id=7a5d1456&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5d1456",
  null
  
)

export default component.exports